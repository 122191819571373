import styled from 'styled-components';

import {
  IMarginProps,
  marginPropsStyles,
} from '~/shared/interfaces/IMarginProps';

type TextProps = IMarginProps;

export const Text = styled.span<TextProps>`
  color: ${({ theme }) => theme.colors.red};
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  font-weight: 600;

  letter-spacing: 0.5px;

  ${marginPropsStyles};
`;
