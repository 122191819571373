import styled from 'styled-components';

import {
  IMarginProps,
  marginPropsStyles,
} from '~/shared/interfaces/IMarginProps';

export const Label = styled.span<IMarginProps>`
  color: ${({ theme }) => theme.colors.grey[600]};
  font-weight: 600;
  display: inline-block;
  font-size: 12px;
  width: 100%;
  text-align: left;

  letter-spacing: 0.5px;

  ${marginPropsStyles}
`;
