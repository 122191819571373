import { FC } from 'react';

import Image from 'next/image';

import { Container, LogoWrapper } from './styles';
import kdsFacilLogo from '../../../../../public/kds-logo.png';

interface IAuthHeaderProps {
  title: string;
  subtitle: string;
}

export const AuthHeader: FC<IAuthHeaderProps> = ({ title, subtitle }) => {
  return (
    <>
      <LogoWrapper>
        <Image
          src={kdsFacilLogo}
          alt="kds facil"
          width={360}
          height={200}
          objectFit="contain"
          objectPosition="center"
          priority
          quality={100}
        />
      </LogoWrapper>

      <Container>
        <h1>{title}</h1>

        <p>{subtitle}</p>
      </Container>
    </>
  );
};
