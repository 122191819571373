import styled, { css } from 'styled-components';

import {
  IMarginProps,
  marginPropsStyles,
} from '~/shared/interfaces/IMarginProps';

import { TInputSize } from './types';

interface IInputWrapperProps {
  isTypePassword: boolean;
  hasIcon: boolean;
  hasError: boolean;
  size: TInputSize;
}

export const Container = styled.label<IMarginProps>`
  ${marginPropsStyles}
`;

const getHeight: Record<TInputSize, string> = {
  lg: '48px',
  md: '42px',
  sm: '36px',
};

export const InputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  align-items: center;
  height: ${({ size }) => getHeight[size]};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  transition: all ease-in 0.2s;
  overflow: hidden;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme, hasError }) =>
    hasError &&
    css`
      border-color: ${theme.colors.red} !important;

      svg {
        color: ${theme.colors.red} !important;
      }
    `}

  > input {
    background: none;
    height: 100%;
    flex: 1;
    padding: ${({ theme, size }) =>
      `0 ${size === 'lg' ? theme.spacing.sm : '12px'}`};
    font-size: ${({ size }) => (size === 'sm' ? '12px' : '14px')};
    line-height: ${({ size }) => getHeight[size]};
    color: ${({ theme }) => theme.colors.text};

    ${({ isTypePassword }) =>
      isTypePassword &&
      css`
        padding-right: 0;
      `}

    ${({ hasIcon }) =>
      hasIcon &&
      css`
        padding-left: 0;
      `}

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey[200]};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.grey[50]};
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 48px;

  > svg {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const EndContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-width: 48px;

  > svg {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const PasswordButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 48px;

  > svg {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.text};
  }
`;
