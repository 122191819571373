import {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from 'react';

import { FiEye, FiEyeOff } from 'react-icons/fi';
import { IconType } from 'react-icons';

import { v4 } from 'uuid';

import { IMarginProps } from '~/shared/interfaces/IMarginProps';

import { TInputSize } from './types';
import {
  Container,
  InputWrapper,
  PasswordButton,
  IconWrapper,
  EndContentWrapper,
} from './styles';
import { Tooltip } from '../Tooltip';
import { Label } from '../Label';
import { ErrorMessage } from '../ErrorMessage';

export interface IInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>,
    IMarginProps {
  label?: string;
  icon?: IconType;
  endContent?: ReactNode;
  error?: string;
  size?: TInputSize;
  hideErrorMessage?: boolean;
  containerStyle?: CSSProperties;
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  {
    label,
    icon: Icon,
    endContent,
    type,
    error,
    m,
    ml,
    mr,
    mt,
    mh,
    mb,
    mv,
    size = 'md',
    containerStyle = {},
    hideErrorMessage = false,
    id: propsId,
    ...props
  },
  ref
) => {
  const id = v4();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleIsPasswordVisible = (): void => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <Container
      m={m}
      mv={mv}
      mh={mh}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      htmlFor={propsId || id}
    >
      {!!label && <Label mb="xxs">{label}</Label>}

      <InputWrapper
        hasIcon={!!Icon}
        isTypePassword={type === 'password'}
        hasError={!!error}
        size={size}
        style={containerStyle}
      >
        {!!Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}

        <input
          type={
            type === 'password'
              ? isPasswordVisible
                ? 'text'
                : 'password'
              : type
          }
          {...props}
          id={propsId || id}
          ref={ref}
        />

        {endContent ? (
          <EndContentWrapper>{endContent}</EndContentWrapper>
        ) : (
          type === 'password' && (
            <Tooltip
              label={isPasswordVisible ? 'Esconder senha' : 'Mostrar senha'}
            >
              <PasswordButton type="button" onClick={toggleIsPasswordVisible}>
                {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
              </PasswordButton>
            </Tooltip>
          )
        )}
      </InputWrapper>

      {!hideErrorMessage && <ErrorMessage mt="xxs">{error}</ErrorMessage>}
    </Container>
  );
};

export const Input = forwardRef(BaseInput);
