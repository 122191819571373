import { NextPage } from 'next';

import { SignIn } from '~/modules/auth/pages/SignIn';

import { withSSRGuest } from '~/shared/utils/withSSRGuest';
import { ModalProvider } from '~/shared/hooks/useModal';

export const getServerSideProps = withSSRGuest(async () => {
  return {
    props: {},
  };
});

const SignInPage: NextPage = () => (
  <ModalProvider>
    <SignIn />
  </ModalProvider>
);
export default SignInPage;
