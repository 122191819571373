import {
  FC,
  useState,
  ChangeEventHandler,
  useCallback,
  FormEventHandler,
} from 'react';

import { FiLock, FiUser } from 'react-icons/fi';

import { useRouter } from 'next/router';

import { IKdsApiDisplay } from '~/modules/board/interfaces/IKdsApiDisplay';
import { useDisplay } from '~/modules/board/hooks/display';
import { OrderStatusEnum } from '~/modules/board/enums/OrderStatusEnum';
import { useAuth } from '~/modules/auth/hooks/auth';

import { addBearerToken } from '~/shared/services/api';
import { useToast } from '~/shared/hooks/useToast';
import { useModal } from '~/shared/hooks/useModal';
import { useCookies } from '~/shared/hooks/useCookies';
import { routes } from '~/shared/constants/routes';
import { cookies } from '~/shared/constants/cookies';
import { Input } from '~/shared/components/Form/Input';
import { FormGroup } from '~/shared/components/Form/FormGroup';
import { Button } from '~/shared/components/Form/Button';
import { DisplaySelectionModal } from '~/shared/components/DisplaySelectionModal';

import { IAuthResponse } from '../../interfaces/IAuthResponse';
import { IAuthRequest } from '../../interfaces/IAuthRequest';
import { AuthHeader } from '../../components/AuthHeader';

export const SignIn: FC = () => {
  const { display, setDisplay, setStatusJourney } = useDisplay();
  const { signIn, setAuthData } = useAuth();
  const cookiesManager = useCookies();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAuthRequest>({} as IAuthRequest);
  const { openModal, closeModal } = useModal();
  const router = useRouter();

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value, name } = event.target;
      setData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );

  const onSelectDisplay = useCallback(
    (authResponse: IAuthResponse, selectedDisplay: IKdsApiDisplay) => {
      const { accessToken, configuration, companyConfigurationId } =
        authResponse;
      cookiesManager.setItem(cookies.AUTH_TOKEN, accessToken);
      cookiesManager.setItem(cookies.DISPLAY, selectedDisplay._id);

      if (
        !selectedDisplay.statusJourney ||
        !selectedDisplay.statusJourney.length
      ) {
        Object.assign(selectedDisplay, {
          statusJourney: configuration?.settings?.enabledStatus || [],
        });
      }

      cookiesManager.setItem(
        cookies.DISPLAY_STATUS_JOURNEY,
        JSON.stringify(selectedDisplay.statusJourney || [])
      );
      cookiesManager.setItem(
        cookies.COMPANY_CONFIGURATION_ID,
        companyConfigurationId
      );
      cookiesManager.setItem(
        cookies.CONFIGURATION,
        JSON.stringify(configuration)
      );

      setDisplay(selectedDisplay._id);
      setStatusJourney(selectedDisplay.statusJourney as OrderStatusEnum[]);
      setAuthData(authResponse);
      addBearerToken(accessToken);

      const notificationActive = cookiesManager.getItem(
        cookies.NOTIFICATIONS_ACTIVE
      );
      const notificationVolume = cookiesManager.getItem(
        cookies.NOTIFICATIONS_VOLUME
      );

      if (!notificationActive) {
        cookiesManager.setItem(cookies.NOTIFICATIONS_ACTIVE, String(true));
      }

      if (!notificationVolume) {
        cookiesManager.setItem(cookies.NOTIFICATIONS_VOLUME, String(100));
      }

      router.push(routes.board);
    },
    [cookiesManager, router, setAuthData, setDisplay, setStatusJourney]
  );

  const handleSubmit: FormEventHandler = async (ev) => {
    ev.preventDefault();
    try {
      setLoading(true);
      const authResponse = await signIn({
        email: data.email,
        password: data.password,
      });

      openModal(DisplaySelectionModal, {
        modalProps: {
          title: 'Seleção de display',
          closeOnClickOutside: false,
          closeOnEsc: false,
          closeOnX: false,
          size: 'big',
        },
        props: {
          displays: authResponse.configuration.settings.displays,
          current: display,
          onConfirm: (selectedDisplay: IKdsApiDisplay) => {
            closeModal();
            onSelectDisplay(authResponse, selectedDisplay);
          },
        },
      });
    } catch (err) {
      toast.show({
        variant: 'error',
        title: 'Ops',
        description:
          'Houve um erro na autenticação, verifique os dados e tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AuthHeader
        title="Seja bem vindo"
        subtitle="Faça o seu login para entrar no KDS"
      />

      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            icon={FiUser}
            name="email"
            placeholder="Digite seu o e-mail"
            autoComplete="email"
            size="lg"
            onChange={handleChangeInput}
            value={data.email}
          />

          <FormGroup>
            <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder="Digite a sua senha"
              autoComplete="current-password"
              size="lg"
              onChange={handleChangeInput}
              value={data.password}
            />
          </FormGroup>

          <Button
            type="submit"
            backgroundColor="primaryDark"
            textColor="white"
            mt="md"
            size="lg"
            disabled={!data.email || !data.password}
            isLoading={loading}
          >
            Entrar
          </Button>
        </FormGroup>
      </form>
    </>
  );
};
